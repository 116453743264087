$xs-phone: 376px;
$phone: 420px;
$tablet: 770px;
$desktop: 1024px;
$large-desktop: 1441px;
$tv-screen: 1921px;
$xl-desktop: 2000px;

$primary: #7a49c6;
$secondary: #ceef87;
$white: #fff;

@font-face {
  font-family: TrueHack8-Regular;
  src: url('./fonts/TrueHack8-Regular.otf') format('opentype');
}

@font-face {
  font-family: 'Futura Condensed PT Medium';
  font-style: normal;
  font-weight: normal;
  src: local('./fonts/Futura Condensed PT Medium'),
    url('./fonts/futura-condensed-pt-medium-589e44ed1e3a5.woff') format('woff');
}

* {
  padding: 0;
  margin: 0;
  margin-block-start: 0;
  margin-block-end: 0;
  font-family: 'TrueHack8-Regular';
}

*,
::after,
::before {
  box-sizing: border-box;
}

:focus {
  outline: none;
}

h1,
h2,
h3,
h4,
h5,
p,
body {
  // font-family: "proxima", sans-serif;
  margin: 0;
  overflow: hidden;
  font-variant-numeric: tabular-nums;
  font-family: 'Roboto', sans-serif;
  font-family: 'Futura Condensed PT Medium';
}

h1 {
  font-size: 4.5rem;
  line-height: 5rem;
  font-weight: 700;
}

h2 {
  font-size: 3rem;
  line-height: 3.5rem;
  font-weight: 700;
  margin-bottom: 1.5rem;
}

h3 {
  font-size: 2rem;
  line-height: 3rem;
  font-weight: 700;
  margin: 0 0 2.5rem 0;

  @media only screen and (min-width: 0px) and (max-width: $tablet) {
    font-size: 1.5rem;
    line-height: 2.5rem;
    margin: 0 0 2rem 0;
  }
}

h4 {
  font-size: 1.5rem;
  line-height: 2.25rem;
  font-weight: 700;
  margin: 0 0 2rem;

  @media only screen and (min-width: 0px) and (max-width: $tablet) {
    font-size: 1rem;
    line-height: 1.5rem;
  }
}

h5 {
  font-size: 1rem;
  line-height: 2.5rem;
  margin: 1rem 0;
}

p {
  font-size: 1rem;
  margin: 0.5rem 0;
}

body {
  margin: 0;
  color: #2d2d2d;
  font-size: 1rem;
  line-height: 1.5;
  text-align: left;
  width: 100%;
  height: 100%;
}

html,
#root {
  height: 100%;
}

img {
  width: 100%;
  height: auto;
}

section {
  position: relative;
  animation: fadeIn 1s;
}

.truehack-container {
  background: black;
  position: relative;
  width: 100%;
  overflow: hidden;

  &.true {
    overflow: hidden;
    height: 100vh;
    justify-content: normal;
  }

  h1,
  h2,
  h3,
  h5,
  div {
    // font-family: 'proxima';
    font-family: 'Playfair Display SC', serif;
    font-family: 'Roboto', sans-serif;
    font-family: 'Futura Condensed PT Medium';
    color: $secondary;
  }

  p,
  h5 {
    color: $secondary;
    font-size: 1rem;
    font-variant-numeric: tabular-nums;
    font-family: 'Playfair Display SC', serif;
    font-family: 'Roboto', sans-serif;
    font-family: 'Futura Condensed PT Medium';

    @media only screen and (min-width: $phone) and (max-width: 760px) {
      font-size: 75%;
    }
  }

  .header,
  .footer {
    text-transform: uppercase;
    font-size: 10vmin;
    color: $white;
    &__times-up {
      color: $white;
      padding-bottom: 4rem;
      font-size: 12vmin;
      line-height: normal;
    }
  }

  .countdown {
    &__header {
      @media only screen and (min-width: $tv-screen) {
        font-size: 3vmin;
        // margin-bottom: 4vmin;
      }
    }

    &__times-up {
      display: inline-flex;
      justify-content: center;
      font-size: 10vmin;
      line-height: normal;
      color: $secondary;
    }

    @media only screen and (min-width: 0px) and (max-width: $tablet) {
      margin: 0 auto;
    }
    .countdown-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;
      text-align: center;
      width: 100%;
      @media only screen and (min-width: 0px) and (max-width: $phone) {
        height: 100%;
      }
      .countdown-clock {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        margin-bottom: 2rem;
        .countdown-time,
        .countdown-labels {
          display: grid;
          grid-template-columns: repeat(11, minmax(0, 1fr));

          @media only screen and (min-width: 0px) and (max-width: $phone) {
            display: flex;
            flex-direction: column;
            height: 100%;
            justify-content: space-between;
          }
        }
        .countdown-time .countdown-info {
          display: block;
          color: $white;
          @media only screen and (min-width: 0px) and (max-width: $phone) {
            display: block;
          }
        }
        .countdown-labels {
          @media only screen and (min-width: 0px) and (max-width: $phone) {
            display: none;
          }
        }
        @media only screen and (min-width: 0px) and (max-width: $phone) {
          display: flex;
          flex-direction: row;
          height: 100%;
        }
      }
    }

    .countdown-num,
    .splitter {
      grid-column: auto / span 2;
      position: relative;
      display: inline-flex;
      justify-content: center;
      font-size: 22vmin;
      line-height: normal;
      color: $secondary;

      @media only screen and (min-width: $tablet) and (max-width: $desktop) {
        font-size: 10rem;
      }

      @media only screen and (min-width: 0px) and (max-width: $tablet) {
        font-size: 8rem;
      }

      @media only screen and (min-width: $tv-screen) {
        font-size: 22vmin;
      }
      @media only screen and (min-width: $xs-phone) and (max-width: $phone) {
        font-size: 400%;
      }
      @media only screen and (min-width: 0px) and (max-width: $xs-phone) {
        font-size: 250%;
      }
    }
    .splitter {
      grid-column: auto / span 1;
      @media only screen and (min-width: 0px) and (max-width: $phone) {
        display: none;
      }
    }
    .time {
      grid-column: auto / span 2;
    }
    .countdown-info {
      grid-column: auto / span 2;
      text-transform: uppercase;
      color: $secondary;
      font-size: 2vw;
      width: 100%;
      font-family: 'Futura', sans-serif;

      @media only screen and (min-width: 0px) and (max-width: $tablet) {
        font-size: 1.25rem;
      }

      @media only screen and (min-width: $tv-screen) {
        font-size: 3vmin;
      }
    }
  }
  .container {
    position: relative;
    display: grid;
    height: 100vh;
    grid-template-columns: repeat(12, minmax(0, 1fr));
    grid-template-rows: repeat(12, minmax(0, 1fr));
    padding: 2.5rem;
    column-gap: 2.5rem;

    background-color: $secondary;

    @media only screen and (min-width: 0px) and (max-width: $tablet) {
      display: flex;
      flex-direction: column;
    }
  }
  svg,
  video,
  img {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  svg {
    padding: 40px;
    @media only screen and (min-width: 0px) and (max-width: $desktop) {
      left: -50%;
      right: -50%;
      width: 200%;
    }
  }
  video,
  img {
    @media only screen and (min-width: 0px) and (max-width: $tablet) {
      display: none;
    }
  }
  .right {
    grid-column: auto / span 7;
    grid-row: auto / span 12;

    display: flex;
    flex-direction: column;
  }
  .countdown {
    height: 100%;
    width: 100%;
    grid-column: auto / span 12;
    grid-row: auto / span 12;

    // background: url('./assets/bg-shape.svg') no-repeat center;
    background-size: 100% 100%;

    padding: 2rem 12rem;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .countdown-container {
      display: flex;
    }
    @media only screen and (min-width: $tablet) and (max-width: $desktop) {
      padding: 2rem;
    }
    @media only screen and (min-width: $phone) and (max-width: $tablet) {
      height: 100%;
      width: 100%;
      padding: 0rem;
    }
    @media only screen and (min-width: 0px) and (max-width: $phone) {
      height: 100%;
      padding: 3rem 1rem;
    }
  }
}
// }
.progressRoot {
  pointer-events: none;
}
.progressContainer {
  background-color: black !important;
  border: solid 2px $primary;
  border-radius: 0 !important;
  &.finished {
    background-color: $primary !important;
  }
}
.progress {
  background-color: $primary !important;
}
.progressTime {
  display: none;
}
p.label {
  font-size: 1rem;
  @media only screen and (min-width: 0px) and (max-width: $tablet) {
    font-size: 100%;
  }
}
p.label.started {
  font-size: 1.5rem;
}
.invert p.label.started {
  color: black !important;
}
.finished .invert p.label.started {
  font-size: 1rem;
}
@keyframes blinker {
  45% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  70% {
    opacity: 0;
  }
  75% {
    opacity: 1;
  }
}
